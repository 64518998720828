// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other navigation in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fleet-adventures-primary: mat.define-palette(mat.$indigo-palette);
$fleet-adventures-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$fleet-adventures-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fleet-adventures-theme: mat.define-light-theme(
  (
    color: (
      primary: $fleet-adventures-primary,
      accent: $fleet-adventures-accent,
      warn: $fleet-adventures-warn,
    ),
  )
);

// Include theme styles for core and each components used in your app.
// Alternatively, you can import and @include the theme mixins for each components
// that you are using.
@include mat.all-legacy-component-themes($fleet-adventures-theme);

/* You can add global styles to this file, and also import other style files */

// SWAL styles (in order for customClass to work with Angular, they must be defined here
.swalTopRightClose {
  // positions the close button in the top right of alert
  position: absolute !important;
  left: 87% !important;
  top: 3% !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

html,
body {
  height: 100%;
}

html {
  background-color: #ffffffba;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  width: 100%;
}

// @import '~ngx-toastr/toastr';
@import "~bs-stepper/dist/css/bs-stepper.min.css";
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900|Open Sans:400,600,800");





/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeicons/primeicons.css";


.unavailableDay {
  border-radius: 0%;
}
.p-dropdown {
  width: 100% !important;
  border: #ced4da solid 1px !important;
}

.availableDay {
  .mat-calendar-body-cell-content {
    background: #62b262;
    border-radius: 0%;
    border-top-width: 10px;
    border-color: black !important;
    border-style: solid !important;
    border-width: 1px !important;
  }
}

.invalidDays {
  border-radius: 1px !important;
  pointer-events: none;
  text-decoration: line-through;
}

.md-drppicker td.in-range {
  background-color: #7c83b0 !important;
  border-color: transparent !important;
  color: #000 !important;
}
.md-drppicker td.off {
  background-color: #fff !important;
  border-color: transparent !important;
  color: #999 !important;
}

// si .md-drppicker td.in-range tambien tiene la clase .off, entonces se aplica el estilo de .off
.md-drppicker td.active.off {
  background-color: #fff !important;
  border-color: transparent !important;
  color: #999 !important;
}

.md-drppicker td.active {
  background-color: #3f51b5 !important;
  border-color: transparent !important;
  color: #000 !important;
}

.md-drppicker td.invalidDays.off.in-range {
  background-color: #7c83b0 !important;
  border-color: transparent !important;
  color: #999 !important;
}

.lessAvailableDay {
  background: #62b262;
  border-radius: 0%;
}

.mat-calendar-body-selected {
  border-radius: 0 !important;
  background-color: #3f51b5 !important;
  color: white;
}

.mat-calendar-body-in-range {
  .mat-calendar-body-cell-content {
    border-radius: 0% !important;
    background-color: #3f51b5 !important;
    z-index: 1;
    color: white;
  }
}

.mat-calendar-table {
  tbody {
    tr {
      --bs-gutter-x: -4.5rem !important;
    }
  }
}

.iti {
  display: block !important;
  margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.btn-primary {
  background-color: #104463 !important;
  border-color: #104463 !important;
}
.p-dropdown-panel {
  height: auto !important;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}

.fc-h-event .fc-event-title {
  cursor: pointer !important;
}

.fc-event-title {
  cursor: pointer !important;
}

td.fc-list-event-title {
  cursor: pointer !important;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  display: none !important;
}

.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active {
  background-color: #114463 !important;
  border-color: #114463 !important;
}

.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button.fc-button-active {
  background-color: #114463 !important;
  border-color: #114463 !important;
}

.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard
  .fc-toolbar
  .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0) !important;
}

@media (max-width: 990px) {
  .fc-header-toolbar .fc-toolbar .fc-toolbar-ltr {
    display: flow-root !important;
  }
}

.cdk-overlay-container {
  z-index: 1000 !important;
}

// Provides a box-shadow on popovers
.popover-body{
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.1), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 5px 5px -3px rgba(0, 0, 0, 0.16);
}

.ng-placeholder {
  color: #1b1b1b !important;
}

@keyframes blink {
  0%, 20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Estilos para la animación de los puntos suspensivos */
.dots::after {
  content: "...";
  display: inline-block;
  animation: blink 1.5s infinite;
  width: 24px; /* Ajusta el ancho según sea necesario */
  text-align: left;
  vertical-align: bottom;
}

/* Ajuste de diseño para evitar que el texto se mueva */
.swal2-content div {
  display: inline-block;
  vertical-align: bottom;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #114463;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #114463;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #114463;
}

.mat-datepicker-toggle:active{
  color: #114463;
}

.mat-raised-button.mat-primary {
  background-color: #114463;
}

.mat-calendar-body-in-range .mat-calendar-body-cell-content {
  background-color: #114463 !important;
}

.mat-calendar-body-selected {
  background-color: #114463 !important;
}
